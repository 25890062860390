import { styled } from 'styled-components';

export const TileBodyExpand = styled.div`
  height: 100%;

  // the tile
  > div:first-child {
    display: flex;
    flex-direction: column;

    // the tile body
    > div:nth-child(2) {
      flex: 1;
    }
  }
`;
TileBodyExpand.displayName = 'TileBodyExpand';
