import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import GoTo from '../../../components/buttons/GoTo';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileCol,
  SummaryTileFooter,
  SummaryTileHeader,
  SummaryError,
  TileDimmer,
} from '../../../components/Tile/TileSummary.styles';
import { mf_Players } from '../../../apollo';
import { ROTATIONS } from '../../../utils/constants/charting';
import HavocChart from '../../../visualisations/HavocChart/HavocChart';
import { useGetHavocEvents } from '../../team/TeamHavoc/TeamHavoc.hooks';
import {
  OptionWithIcon,
  SingleValueWithIcon,
} from '../../../components/DropdownFilter/DropdownFilter.components';
import {
  HAVOC_TILE_COLOR_MODES,
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_TYPE_ALL,
  HAVOC_Y_MODE_SNAP,
} from '../../../visualisations/HavocChart/HavocChart.constants';
import {
  getPlayers,
  getPositions,
} from '../../team/TeamHavoc/TeamHavoc.DataManipulation';
import HavocChartKey from '../../../visualisations/HavocChart/HavocChartKey/HavocChartKey';
import useQueryString from '../../../utils/hooks/useQueryString';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';

const PlayerHavocTile = () => {
  const mf_PlayersRV = useReactiveVar(mf_Players);
  const [colorMode, setColorMode] = useQueryString(
    'havocDisplayMode',
    HAVOC_COLOR_MODE_LOCATIONS.value
  );

  const {
    loading: loadingHavoc,
    error: errorHavoc,
    teamHavocData,
  } = useGetHavocEvents(true, HAVOC_TYPE_ALL.value);
  const hasError = !!errorHavoc;
  const isLoading = !!loadingHavoc;
  const relevantPlayers = getPlayers(teamHavocData);
  const positionFrequencies = getPositions(teamHavocData);
  const havocData = teamHavocData?.filter((h) => h.playerId === mf_PlayersRV);

  return (
    <SummaryTile>
      <SummaryTileHeader>
        <div>
          <h3>Havoc</h3>
        </div>
        <div>
          {!hasError && !isLoading && havocData?.length !== 0 && (
            <DropdownWrapper>
              <Dropdown
                id="havocColourModeDrop"
                components={{
                  Option: OptionWithIcon,
                  SingleValue: SingleValueWithIcon,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(selectedOption) =>
                  setColorMode(selectedOption.value)
                }
                options={HAVOC_TILE_COLOR_MODES}
                value={HAVOC_TILE_COLOR_MODES.find(
                  (f) => f.value === colorMode
                )}
                labelPosition="none"
                label="Havoc chart display mode"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
          )}
          <GoTo
            // eslint-disable-next-line max-len
            href={`/team/havocSummary/:leagues/:seasons/:teams/?player=${mf_PlayersRV}&position=${HAVOC_POSITION_OPTION_ANY.value}&color=${colorMode}`}
            linkName="Open havoc chart"
            iconSize="small"
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody>
        {isLoading && <TileDimmer />}
        {havocData?.length === 0 && !isLoading && (
          <TileDimmer active>
            <p>No Data available</p>
          </TileDimmer>
        )}
        {hasError && !isLoading && (
          <SummaryError>
            <Icon size="small" variant="Warning" colour="primary.main" />
            There has been an error.
          </SummaryError>
        )}
        <div className="chartWrapper">
          {!hasError && !isLoading && havocData?.length !== 0 && (
            <>
              <HavocChart
                data={havocData}
                orientation={ROTATIONS.VERTICAL_DOWN}
                displayYMode={HAVOC_Y_MODE_SNAP.value}
                colorMode={colorMode}
                fieldFocusMode={HAVOC_FOCUS_MODE_BOX.value}
                players={relevantPlayers}
                showPaths={false}
                selectedEvents={null}
                setSelectedEvent={() => {}}
                isInteractive={false}
              />
              <HavocChartKey
                colorMode={colorMode}
                players={relevantPlayers}
                positions={positionFrequencies}
                isFixedSize
              />
            </>
          )}
        </div>
      </SummaryTileBody>
      <SummaryTileFooter>
        <SummaryTileCol>
          {isLoading && (
            <Loader active inline size="small" content="Loading Data" />
          )}
        </SummaryTileCol>
        <SummaryTileCol />
      </SummaryTileFooter>
    </SummaryTile>
  );
};

export default PlayerHavocTile;
