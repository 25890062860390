import { DEFENSIVE_SCHEME_GROUPS } from '../TeamDefensiveScheme/TeamDefensiveScheme.constants';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { TABLE_CHART } from '../../../visualisations/TableChart/TableChart.constants';

const { STRING, COUNT, PERCENTAGE_PROVIDED } = API_STAT_UNITS;
const { PLAY_PERCENTAGE_SMALL } = TABLE_CHART;
const { FRONT, BLITZ, COVERAGE } = DEFENSIVE_SCHEME_GROUPS;

export const DEFENSIVE_SCHEME_TILE_OPTIONS = [
  {
    label: 'Front',
    value: FRONT,
  },
  {
    label: 'Blitz',
    value: BLITZ,
  },
  {
    label: 'Coverage',
    value: COVERAGE,
  },
];

export const DEFENSIVE_SCHEME_SUMMARY_COLUMNS = [
  {
    key: 'group',
    label: 'Group',
    units: STRING,
    description: 'Category grouping',
    isHidden: true,
  },
  {
    key: 'category',
    label: 'Name',
    units: STRING,
    description: 'Category of plays',
  },
  {
    id: 'playCount',
    key: 'playCount',
    label: 'Play Count',
    units: COUNT,
    description: 'Count of plays',
  },
  {
    id: 'playPerc',
    key: 'playPerc',
    label: 'Play %',
    units: PERCENTAGE_PROVIDED,
    description: 'Percentage of plays',
    chart: PLAY_PERCENTAGE_SMALL, // invokes getTableChart function, returning TableChartPlayPercBar (small version)
  },
];
