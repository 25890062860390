import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { Dropdown, Icon } from '@statsbomb/kitbag-components';
import { TileInlineLoader } from './TeamOverview.styles';
import Tile from '../../../components/Tile/Tile';
import GoTo from '../../../components/buttons/GoTo';
import { SummaryError } from '../../../components/Tile/TileSummary.styles';
import Dimmer from '../../../components/Dimmer/Dimmer';
import {
  DEFENSIVE_SCHEME_AVG_KEYS,
  DEFENSIVE_SCHEME_GROUPS,
} from '../TeamDefensiveScheme/TeamDefensiveScheme.constants';
import { GET_DEFENSIVE_SCHEME } from '../TeamDefensiveScheme/getDefensiveScheme';
import { DropdownWrapper } from '../../../components/DropdownFilter/Dropdown.styles';
import { SingleValue } from '../../../components/DropdownFilter/DropdownFilter.components';
import {
  DEFENSIVE_SCHEME_SUMMARY_COLUMNS,
  DEFENSIVE_SCHEME_TILE_OPTIONS,
} from './TeamDefensiveScheme.Tile.constants';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  formatDefensiveScheme,
  groupDataByLabel,
} from '../TeamDefensiveScheme/TeamDefensiveScheme.dataManipulation';
import SortableTable from '../../../components/Tables/SortableTable/SortableTable';
import { TileBodyExpand } from './TeamDefensoiveScheme.Tile.styles';

const TeamDefensiveSchemeTile = () => {
  const [defensiveSchemeType, setDefensiveSchemeType] = useQueryString(
    'defensiveScheme',
    DEFENSIVE_SCHEME_GROUPS.FRONT
  );
  const [schemeData, setSchemeData] = useState([]);
  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_DEFENSIVE_SCHEME);

  useEffect(() => {
    const rawSchemeData = formatDefensiveScheme(
      data?.defensiveScheme?.filter(
        (d) =>
          d.statsType === defensiveSchemeType &&
          d.team === DEFENSIVE_SCHEME_AVG_KEYS.TEAM
      ),
      DEFENSIVE_SCHEME_SUMMARY_COLUMNS
    );

    setSchemeData(groupDataByLabel(rawSchemeData));
  }, [data, defensiveSchemeType]);

  return (
    <TileBodyExpand>
      <Tile margin="0" border="1px" padding="0" minHeight="100%">
        <Tile.Header $border="2px">
          <h3>Defensive Scheme</h3>
          <div className="buttons">
            <DropdownWrapper>
              <Dropdown
                id="team-tackling-tile-dropdown"
                components={{
                  SingleValue,
                }}
                isSearchable={false}
                menuAlignment="right"
                menuPlacement="bottom"
                onChange={(selectedOption) =>
                  setDefensiveSchemeType(selectedOption.value)
                }
                options={DEFENSIVE_SCHEME_TILE_OPTIONS}
                value={DEFENSIVE_SCHEME_TILE_OPTIONS.find(
                  (item) => item.value === defensiveSchemeType
                )}
                labelPosition="none"
                label="Defensive scheme display options"
                variant="tertiary"
                size="small"
              />
            </DropdownWrapper>
            <GoTo
              href="/team/defensiveScheme/:leagues/:seasons/:teams"
              linkName="Open defensive scheme"
              iconSize="small"
            />
          </div>
        </Tile.Header>
        <Tile.Body $overflowY="scroll" $padding="0 0.5rem" $margin="0">
          {hasError && !isLoading && (
            <SummaryError>
              <Icon size="small" variant="Warning" colour="primary.main" />
              There has been an error.
            </SummaryError>
          )}
          {(isLoading || hasError) && <Dimmer active />}
          {!isLoading && (
            <SortableTable
              name="defensive-scheme-summary-table"
              headers={DEFENSIVE_SCHEME_SUMMARY_COLUMNS}
              rows={schemeData}
              showExpanding={false}
            />
          )}
          {isLoading && (
            <TileInlineLoader>
              <Loader
                active
                inline
                size="small"
                content="Loading Defensive Scheme Data"
              />
            </TileInlineLoader>
          )}
        </Tile.Body>
      </Tile>
    </TileBodyExpand>
  );
};

export default TeamDefensiveSchemeTile;
