import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  StyledTile,
  StyledTileBody,
  StyledTileHeader,
  StyledTileAccordionHeader,
  StyledTileAccordionBody,
} from './Tile.styles';

const Tile = forwardRef(
  (
    {
      children,
      full,
      widthLimit,
      dataTestId,
      margin,
      border,
      padding,
      maxHeight,
      minHeight,
      className,
      opacity,
      width,
    },
    ref
  ) => (
    <StyledTile
      ref={ref}
      className={classNames(
        {
          full,
        },
        `${className}`
      )}
      $maxWidth={widthLimit}
      $maxHeight={maxHeight}
      $minHeight={minHeight}
      $margin={margin}
      $border={border}
      $padding={padding}
      $opacity={opacity}
      $width={width}
      data-testid={dataTestId}
    >
      {children}
    </StyledTile>
  )
);

Tile.propTypes = {
  children: PropTypes.node.isRequired,
  full: PropTypes.bool,
  widthLimit: PropTypes.string,
  dataTestId: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  className: PropTypes.string,
  opacity: PropTypes.number,
  width: PropTypes.string,
};

Tile.defaultProps = {
  full: false, // Probably needs renaming
  widthLimit: '',
  dataTestId: '',
  margin: '',
  border: '',
  padding: '',
  maxHeight: '',
  minHeight: '',
  className: '',
  opacity: 1,
  width: '',
};

Tile.Header = StyledTileHeader;
Tile.Body = StyledTileBody;
Tile.AccordionHeader = StyledTileAccordionHeader;
Tile.AccordionBody = StyledTileAccordionBody;

export default Tile;
