import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { TableEPAWrapper } from './TableChartEPA_Bar.styles';
import { getPalette } from '../../utils/visualisations/visPalettes';

// An inline bar for table for percentage-based data
const TableChartPlayPercBar = ({ value, cellHeight, barHeight, max }) => {
  const { colours, isDark } = useTheme();
  const visPalette = getPalette(isDark);

  // Set chart dimensions
  const width = 100;

  // Convert percentage value string to a number and handle percentages
  const percentageValue = parseFloat(value.replace('%', ''));
  const scale = (percentageValue / max) * width; // Scale the bar

  return (
    <TableEPAWrapper className="hover-svg">
      <svg width={width} height={cellHeight}>
        <rect
          x={0}
          y={(cellHeight - barHeight) / 2}
          width={scale}
          height={barHeight}
          fill={visPalette.objects.n1.main}
        />
        <line
          x1={0}
          y1="0"
          x2={0}
          y2={cellHeight}
          stroke={colours.canvas.primary.main}
          strokeWidth="1"
        />
        <line
          x1={width}
          y1="0"
          x2={width}
          y2={barHeight}
          stroke={colours.canvas.primary.main}
          strokeWidth="1"
        />
      </svg>
      <p>{value}</p>
    </TableEPAWrapper>
  );
};

TableChartPlayPercBar.propTypes = {
  value: PropTypes.string.isRequired, // Expect the percentage value as a string, e.g., "45%"
  max: PropTypes.number, // The maximum value for the percentage
  cellHeight: PropTypes.number, // The height of the cell
  barHeight: PropTypes.number, // The height of the bar
};

TableChartPlayPercBar.defaultProps = {
  max: 50,
  cellHeight: 32,
  barHeight: 20,
};

export default TableChartPlayPercBar;
