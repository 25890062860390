import { ALIGNMENT } from '../../../utils/constants/alignment';
import { API_ROSTER_POSITION_KEYS } from '../../../utils/constants/api';

// chart sizing
export const COMPARISON_TABS_HEIGHT = 60; // in px, the part of the page with two tabs

// chart settings
export const COMPARISON_DEFAULT_MIN_PLAYS = 100;
export const COMPARISON_DEBOUNCE_DELAY = 500;
export const COMPARISON_DEFAULT_WEIGHT = 3; // 25/75
export const COMPARISON_TILE_WIDTH = 200; // in px
export const COMPARISON_NUMBER_OF_TILES = 5;

// chart mode (which tab)
export const COMPARE_SIMILAR = 0;
export const COMPARE_SELECTED = 1;

// comparison tile mode
export const COMPARE_TILE_DATA = 0; // comparison tile has data
export const COMPARE_TILE_ADD = 1; //  first empty tile, it offers an add function
export const COMPARE_TILE_EMPTY = 2; // tile that is visible but without data
export const COMPARE_TILE_HIDDEN = 3; // comparison tile isn't being used (hidden for layout purposes)
export const COMPARE_TILE_ERROR = 4; // comparison tile has an error
export const COMPARE_TILE_DEFAULT_STATE = [
  COMPARE_TILE_ADD,
  COMPARE_TILE_HIDDEN,
  COMPARE_TILE_HIDDEN,
  COMPARE_TILE_HIDDEN,
  COMPARE_TILE_HIDDEN,
];

export const RADAR_DISPLAY_MODE = {
  TRAIT: 'trait',
  PERFORMANCE: 'performance',
  BOTH: 'both',
};

export const COMPARISON_TABLE_COLS = [
  {
    label: 'player',
    key: 'statValue',
    alignment: ALIGNMENT.LEFT,
  },
];

export const COMPARISON_TABLE_HEADERS = [
  {
    label: 'player',
    key: 'statValue',
    alignment: ALIGNMENT.RIGHT,
  },
];

export const COMPARISON_WEIGHT_OPTIONS = [
  { label: '100/0', value: 0, trait: 1, performance: 0 },
  { label: '75/25', value: 1, trait: 0.75, performance: 0.25 },
  { label: '50/50', value: 2, trait: 0.5, performance: 0.5 },
  { label: '25/75', value: 3, trait: 0.25, performance: 0.75 },
  { label: '0/100', value: 4, trait: 0, performance: 1 },
];

// available templates, based on the base player position
// https://www.notion.so/statsbomb/PRD-Player-Comparison-b7798cb314f04d678dcca923127123e2
export const COMPARISON_TEMPLATE_MAPPING = {
  [API_ROSTER_POSITION_KEYS.QUARTERBACK]: [
    API_ROSTER_POSITION_KEYS.QUARTERBACK,
  ],
  [API_ROSTER_POSITION_KEYS.RUNNING_BACK]: [
    API_ROSTER_POSITION_KEYS.RUNNING_BACK,
    API_ROSTER_POSITION_KEYS.WIDE_RECEIVER,
    API_ROSTER_POSITION_KEYS.TIGHT_END,
  ],
  [API_ROSTER_POSITION_KEYS.WIDE_RECEIVER]: [
    API_ROSTER_POSITION_KEYS.RUNNING_BACK,
    API_ROSTER_POSITION_KEYS.WIDE_RECEIVER,
    API_ROSTER_POSITION_KEYS.TIGHT_END,
  ],
  [API_ROSTER_POSITION_KEYS.TIGHT_END]: [
    API_ROSTER_POSITION_KEYS.RUNNING_BACK,
    API_ROSTER_POSITION_KEYS.WIDE_RECEIVER,
    API_ROSTER_POSITION_KEYS.TIGHT_END,
  ],
  [API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN]: [
    API_ROSTER_POSITION_KEYS.OFFENSIVE_LINEMAN,
  ],
  [API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN]: [
    API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN,
    API_ROSTER_POSITION_KEYS.LINEBACKER,
  ],
  [API_ROSTER_POSITION_KEYS.LINEBACKER]: [
    API_ROSTER_POSITION_KEYS.DEFENSIVE_LINEMAN,
    API_ROSTER_POSITION_KEYS.LINEBACKER,
  ],
  [API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK]: [
    API_ROSTER_POSITION_KEYS.DEFENSIVE_BACK,
  ],
};
